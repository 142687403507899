<template>
  <CardHeader :padding="true" :back="true" @back="redirect(true, true)">
    <template #topheader>
      <div class="title-done">
        <feather-icon icon="CheckCircleIcon" class="mr-1" />
        Order Processed!
      </div>
    </template>
    <template>
      <div class="result">
        <div
          class="border result-content bg-secondary bg-lighten-3"
          v-html="result"
        />
        <div class="info-actions text-center">
          <b-button
            @click="copy"
            :variant="copied ? `flat-success` : `flat-secondary`"
            size="sm"
          >
            <feather-icon :icon="!copied ? 'FileIcon' : 'CheckIcon'" />
            {{ !copied ? "Copy" : "Copied" }}
          </b-button>
          <div class="color-secondary" v-if="time_took">
            <feather-icon icon="WatchIcon" />
            {{ time_took }} seconds
          </div>
        </div>
        <div class="border-bottom" />
        <div class="actions mt-2">
          <b-button
            id="toggle-btn"
            :disabled="wpLoading"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="onClickSendToWhatsapp"
            variant="success"
          >
            <b-spinner class="mr-1" v-if="wpLoading" small />
            Send to my Whatsapp
          </b-button>
        </div>
        <div class="actions mt-1">
          <b-button @click="redirect(false, true)" variant="primary">
            New IMEI
          </b-button>
          <b-button @click="redirect(true, false)" variant="primary">
            New Service
          </b-button>
          <AddNote :ordersId="[orderId]" />
        </div>
      </div>
    </template>
    <SendVerificationCodeDialog
      :id="sendVerificationModalId"
      @codeSent="onCodeSent"
    />
    <VerifyCodeDialog
      :id="verifyCodeModalId"
      :mobileNumber="wpMobileNumber"
      @changeNumber="onClickChangeNumber"
    />
  </CardHeader>
</template>

<script>
import { BBadge, BButton } from "bootstrap-vue";
import CardHeader from "@/components/UI/CardHeader.vue";
import AddNote from "@/components/helpers/AddNote.vue";
import SendVerificationCodeDialog from "@/components/whatsapp/SendVerificationCodeDialog.vue";
import VerifyCodeDialog from "@/components/whatsapp/VerifyCodeDialog.vue";
import Ripple from "vue-ripple-directive";
import Whatsapp from "@/services/whatsapp.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";

export default {
  directives: {
    Ripple,
  },
  data: () => ({
    time_took: null,
    orderId: null,
    copied: false,
    result: null,
    wpLoading: false,
    wpMobileNumber: "",
    sendVerificationModalId: "modal-send-to-whatsapp-single",
    verifyCodeModalId: "modal-verification-code-single",
  }),
  computed: {
    ...mapState({ userInfo: "user" }),
    extractText() {
      if (!this.result) {
        return "";
      }
      const div = document.createElement("div");
      div.innerHTML = this.result
        ?.replace(/<\/br>/g, "\n")
        ?.replace(/<br>/g, "\n");
      return div.textContent || div.innerText;
    },
    params() {
      return this.$route.params;
    },
  },
  mounted() {
    const { service, imei, result, order_id, time_took } = this.params;
    if (!imei || !service || !result)
      return this.$router.push({ name: "imei-check" });
      this.orderId = order_id;
    this.time_took = time_took;
    this.result = result;
  },
  methods: {
    onClickChangeNumber() {
      this.$bvModal.show(this.sendVerificationModalId);
    },
    onCodeSent(value) {
      //show verification code dialog
      this.wpMobileNumber = value.mobileNumber;
      this.$bvModal.show(this.verifyCodeModalId);
    },
    async onClickSendToWhatsapp() {
      if (!this.extractText) {
        this.notify({
          variant: "danger",
          text: "Information not found",
        });
        return;
      }
    /*   if (!this.userInfo || !this.userInfo.whatsapp_number) {
        this.$bvModal.show(this.sendVerificationModalId);
      } */
      this.wpLoading = true;
      try {
        const result = await Whatsapp.sendToWhatsapp({
          body: this.extractText,
        });
        if (result.status == 200) {
          this.notify({
            variant: "success",
            text: result.data.message,
          });
        }
      } catch (error) {
        let message = "";
        if (error?.response?.status == 422) {
          this.$bvModal.show(this.sendVerificationModalId);
          message = error.response.data.message;
        } else {
          message = error?.response?.data?.message ?? "Invalid error occurred.";
        }
        this.notify({
          variant: "danger",
          text: message,
        });
      } finally {
        this.wpLoading = false;
      }
    },
    copy() {
      this.$copyText(this.extractText).then(
        () => (this.copied = true),
        () => (this.copied = false)
      );
    },
    redirect(imei = false, service = true) {
      const params = {
        imei: imei ? this.params.imei : null,
        service: service ? this.params.service : null,
      };
      this.$router.push({ name: "imei-check", params });
    },
    notify({ variant = "success", text = "" } = {}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },

  components: {
    CardHeader,
    BBadge,
    BButton,
    AddNote,
    SendVerificationCodeDialog,
    VerifyCodeDialog,
  },
};
</script>

<style lang="scss" scoped>
.result {
  max-width: 500px;
  margin: 40px auto;

  &-content {
    text-align: center;
    padding: 10px;
    border-radius: 7px;
    background-color: #eaf2fd !important;
    color: black;
  }
}

.dark-layout {
  .result-content {
    background-color: #3c4764 !important;
    color: white;
  }
}

.info-actions {
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    text-transform: uppercase;
    margin: 10px;
  }
}
</style>
