<template>
  <b-modal
    v-bind="$attrs"
    no-close-on-esc
    no-close-on-backdrop
    ref="my-modal"
    modal-class="modal-primary"
    title="Verify code!"
    hide-footer
    centered
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="verificationCodeState"
        :label="
          `Enter the 6-digit code sent to your WhatsApp (${mobileNumber}).`
        "
        label-for="name-input"
        invalid-feedback="Code length must be 6 digits."
      >
        <b-form-input
          type="number"
          placeholder="Enter verification code"
          v-model="verificationCode"
          :state="verificationCodeState"
          required
        />
        <small
          @click="onClickChangeMobileNumber"
          class="cursor-pointer text-primary"
          style="text-decoration: underline;"
          >Change mobile number</small
        >
      </b-form-group>
      <div class="footer-addnote">
        <b-button class="mr-1" variant="outline-primary" @click="close" pill>
          Close
        </b-button>
        <b-button type="submit" :disabled="loading" variant="primary" pill>
          <b-spinner class="mr-1" v-if="loading" small />
          Verify code
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Whatsapp from "@/services/whatsapp.service.js";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: ["ordersId"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    mobileNumber: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    verificationCode: "",
    verificationCodeState: null,
  }),
  watch: {
    mobileNumber(newVal, oldVal) {
      console.log("Val ", newVal);
      console.log("old Val ", oldVal);
    },
  },
  methods: {
    onClickChangeMobileNumber() {
      this.$emit("changeNumber");
      this.close();
    },
    checkFormValidity() {
      const valid =
        this.$refs.form.checkValidity() && this.verificationCode.length == 6;
      this.verificationCodeState = valid;
      return valid;
    },
    resetModal() {
      this.verificationCode = "";
      this.verificationCodeState = null;
    },
    close() {
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) return;
      this.loading = true;
      console.log("mobile number ", this.mobileNumber);
      try {
        const response = await Whatsapp.verifyWhatsappNumber({
          code: this.verificationCode,
          mobile_number: this.mobileNumber,
        });
        if (response.status == 200) {
          this.notify({
            variant: "success",
            text: response.data.message,
          });
          this.$store.commit("SET_WHATSAPP_NUMBER", this.mobileNumber);
          this.close();
        }
      } catch (error) {
        this.notify({
          variant: "danger",
          text: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    notify({ variant = "success", text = "" } = {}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },
};
</script>
<style lang="scss" scoped>
.footer-addnote {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
