<template>
  <b-modal
    v-bind="$attrs"
    no-close-on-esc
    no-close-on-backdrop
    ref="my-modal"
    modal-class="modal-primary"
    :title="
      whatsappNumber ? `Update your whatsapp number` : `Verify your whatsapp!`
    "
    hide-footer
    centered
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        :state="mobileNumberState"
        label="Enter your WhatsApp number, including the country code"
        label-for="name-input"
        invalid-feedback="Invalid mobile number format."
      >
        <b-form-input
          type="number"
          placeholder="Enter your full WhatsApp number, e.g., +1234567890."
          v-model="mobileNumber"
          :state="mobileNumberState"
          required
        />
      </b-form-group>
      <div class="footer-addnote">
        <b-button class="mr-1" variant="outline-primary" @click="close" pill>
          Close
        </b-button>
        <b-button type="submit" :disabled="loading" variant="primary" pill>
          <b-spinner class="mr-1" v-if="loading" small />
          Send code
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Whatsapp from "@/services/whatsapp.service.js";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: {
    whatsappNumber: {
      type: String,
      default: null,
      required: false,
    },
    modalId: {
      required: false,
      default: "center",
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    loading: false,
    mobileNumber: "",
    mobileNumberState: null,
  }),
  watch: {
    whatsappNumber(newVal, oldVal) {
      if (newVal != null) {
        this.mobileNumber = Number(newVal);
      } else {
        this.mobileNumber = "";
      }
    },
  },
  methods: {
    checkFormValidity() {
      let regex = /^[\d-]{10,31}(@[\w\.]{1,})?$/;
      const valid =
        this.$refs.form.checkValidity() && regex.test(this.mobileNumber);
      this.mobileNumberState = valid;
      return valid;
    },
    resetModal() {
      this.mobileNumber = "";
      this.mobileNumberState = null;
    },
    close() {
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) return;
      this.loading = true;
      try {
        const response = await Whatsapp.sendVerificationCode({
          mobile_number: this.mobileNumber,
        });
        if (response.status == 200) {
          this.notify({
            variant: "success",
            text: response.data.message,
          });
          this.$emit("codeSent", { mobileNumber: this.mobileNumber });
          this.close();
        }
      } catch (error) {
        this.notify({
          variant: "danger",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    notify({ variant = "success", text = "" } = {}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
  mounted() {
    if (this.whatsappNumber != null) {
      this.mobileNumber = Number(this.whatsappNumber);
    }
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },
};
</script>
<style lang="scss" scoped>
.footer-addnote {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
