import api from "@axios";

class Whatsapp {
  static async sendToWhatsapp(data) {
    try {
      const response = await api.post("/send-whatsapp-message",data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async sendVerificationCode(data) {
    try {
      const response = await api.post( "/send-verification-code",  data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async verifyWhatsappNumber(data) {
    try {
      const response = await api.post("/verify-whatsapp-number", data);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default Whatsapp;
